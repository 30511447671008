import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const ErrorComponent = ({ error, componentStack, resetError }) => {
    useEffect(() => {
        // Log error to Sentry with additional context
        Sentry.withScope((scope) => {
            scope.setExtra('componentStack', componentStack);
            scope.setExtra('errorLocation', window.location.href);
            scope.setExtra('timestamp', new Date().toISOString());
            Sentry.captureException(error);
        });
    }, [error, componentStack]);

    const handleReset = () => {
        try {
            // Clear any potentially corrupted state
            localStorage.removeItem('HEADLESS_AGE_GATE_PASSED');
            sessionStorage.clear();
            
            // Attempt to reset the error boundary
            resetError();
        } catch (resetError) {
            console.error('Failed to reset error boundary:', resetError);
            // Force page refresh as last resort
            window.location.reload();
        }
    };

    return (
        <div className='headless-error-component'>
            <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
                We apologize, but this area has encountered an error.
            </p>
            <button 
                style={{ display: 'block' }} 
                onClick={handleReset}
                className="error-reset-button"
            >
                Reset
            </button>
            <small style={{ display: 'block' }}>
                You may try resetting the area with the above button.
            </small>
            <small>
                If the error persists, please refresh the page from your browser.
            </small>
            {error && (
                <small style={{ display: 'block', marginTop: '2rem' }}>
                    <code>{error.toString()}</code>
                    <details>
                        <summary>Technical Details</summary>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {componentStack}
                        </pre>
                    </details>
                </small>
            )}
        </div>
    );
};

export default ErrorComponent;
